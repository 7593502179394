.dijitTableLayout td.tableContainer-labelCell {
    padding: 4px;
}
.dijitTableLayout td.tableContainer-valueCell {
    padding: 4px;
}

.dijitTableLayout tr td.tableContainer-labelCell:first-child {
    padding-left: 2px;
}
.dijitTableLayout tr td.tableContainer-valueCell:last-child {
    padding-right: 2px;
}
