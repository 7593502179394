.nextgis.claro {
    .dijitMenuTable {
        border-radius: 2px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .dijitMenu {
        border: 0;

        .dijitMenuItem {
            td {
                border: 0;
                padding: 4px 6px;
            }
        }
    }

    .dijitMenu .dijitMenuItemHover td,
    .dijitMenu .dijitMenuItemSelected td,
    .dijitMenuItemHover,
    .dijitComboBoxMenu .dijitMenuItemHover,
    .dijitMenuItemSelected {
        background-image: none;
        background-color: rgba(0, 0, 0, 0.04);
        color: var(--text-base);
    }

    .dijitComboBoxMenu {
        padding-top: 5px;
        padding-bottom: 5px;

        .dijitMenuItem {
            padding: 2px 7px;

            [class^="icon-"],
            [class*=" icon-"] {
                margin-right: 4px;

                &:before {
                    color: #999;
                }
            }
        }
    }
}
