.nextgis.claro {
    .dijitButton {
        cursor: pointer;

        &:hover {
            position: relative;
            &::before {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: var(--hover-opacity-root);
                z-index: 2;
                background-color: currentColor;
                pointer-events: none;
                content: "";
            }
        }

        &--primary,
        &--default {
            margin: 0;
            border-radius: var(--border-radius-root);
            font-size: 16px;

            & + .dijitButton--primary,
            & + .dijitButton--default {
                margin-left: 8px;
            }

            &.dijitButtonDisabled {
                background: #ddd;
                color: #999;
            }

            .dijitButtonNode {
                transition: none;
                border: 0;
                border-radius: var(--border-radius-root);
                background-image: none;
                background-color: inherit;
                padding: 0 16px;
                height: 36px;
                color: inherit;
                font-size: inherit;
                line-height: 35px;
                font-family: var(--ngw-head-font-family-list);

                .dijitReset {
                    line-height: inherit;
                }

                * {
                    vertical-align: baseline;
                }
            }
        }

        &--primary {
            background-color: var(--primary);
            color: var(--on-primary-text);
        }

        &--default {
            background-color: var(--secondary);
            color: var(--on-secondary-text);
        }

        &--s {
            font-size: 14px;

            .dijitButtonNode {
                padding-right: 12px;
                padding-left: 12px;
                height: 32px;
                line-height: 27px;
            }
        }

        &--signal {
            .dijitButtonContents {
                position: relative;
            }

            .dijitButtonContents::after {
                display: none;
                position: absolute;
                top: -1px;
                left: -1px;
                border: 2px solid #fff;
                border-radius: var(--border-radius-root);
                background-color: var(--accent);
                width: 5px;
                height: 5px;
                content: "";
            }

            &-active {
                .dijitButtonContents::after {
                    display: block;
                }
            }
        }
    }
}
