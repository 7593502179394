h1,
h2,
h3 {
    font-weight: var(--ngw-head-font-weight);
    line-height: 1.1;
    font-family: var(--ngw-head-font-family-list);
}

h1 {
    color: var(--text-base);
    font-size: 24px;
}

h2 {
    margin-top: 48px;
    margin-bottom: 24px;
    border-bottom: 0;
    padding-bottom: 0;
    color: var(--text-base);
    font-size: 18px;

    &:first-child {
        margin-top: 0;
    }
}

.text-muted {
    color: var(--text-secondary);
}

.heading {
    margin-top: 20px;
    margin-bottom: 12px;
    color: var(--text-secondary);
    font-weight: var(--ngw-text-font-weight);
    font-size: 13px;
    font-family: var(--ngw-head-font-family-list);
    text-transform: uppercase;
}

.small-text {
    font-size: 13px;
}

.code-text {
    color: #33326d;
    font-family: monospace;
}

.text-center {
    text-align: center;
}
