.dojoxCheckedMultiSelect .dojoxCheckedMultiSelectWrapper {
    margin: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    height: 200px !important;
}

.nextgis.claro {
    .dijitSelect,
    .dijitTextBox {
        border-radius: var(--border-radius-root);
        overflow: hidden;

        .dijitInputField {
            padding: 2px 4px;
        }

        &--medium {
            .dijitInputField {
                padding: 5px;
            }
        }
    }

    .dijitSelect,
    .dijitSelect .dijitButtonContents,
    .dijitTextBox,
    .dijitTextBox .dijitButtonNode {
        border-color: var(--input-border-color);
    }

    .dijitSelectHover,
    .dijitTextBoxHover,
    .dijitSelectFocused,
    .dijitTextBoxFocused {
        background-image: none;
        background-color: #fff;
    }

    .dijitTextBoxFocused {
        border-color: var(--primary);
    }

    .dijitTextArea {
        box-sizing: border-box;
        padding: 4px;
    }
}

.checkbox {
    cursor: pointer;
}

.checkbox__label {
    vertical-align: middle;
}

textarea {
    resize: vertical;
}

.input-group {
    margin-top: 12px;
    margin-bottom: 12px;

    &:first-child {
        margin-top: 0;
    }

    &__label {
        display: inline-block;
        margin-bottom: 4px;
    }

    &--inline {
        .input-group__label {
            margin-right: 4px;
            margin-bottom: 0;
        }
    }
}
