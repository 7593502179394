:root {
    // Colors
    --primary: #076dbf;
    --secondary: #e5eef7;
    --accent: #00c775;
    --danger: #f44336;
    --error: #f44336;
    --warning: #ffba3f;
    --success: #00c775;
    --on-primary-text: #fff;
    --on-secondary-text: var(--primary);
    --on-accent-text: #fff;
    --on-error-text: #fff;
    --on-warning-text: #fff;
    // Global
    --border-radius-root: 4px;
    --link-color: var(--primary);
    --link-hover-color: #2196f3;
    --icon-color: #b3b9be;
    --hover-opacity-root: 0.08;
    --surface-bg-success: rgba(0, 199, 117, 0.24);
    --surface-bg-danger: rgba(244, 67, 54, 0.24);
    // Typography
    --text-base: #212121;
    --text-secondary: #808a93;
    // Dividers
    --divider-color: rgba(0, 0, 0, 0.08);
    // Inputs
    --input-border-color: rgba(0, 0, 0, 0.16);
}

:root {
    --ngw-text-font-family-list: var(--ngw-text-font-family, "Roboto"),
        sans-serif;
    --ngw-text-font-weight: 400;
    --ngw-head-font-family-list: var(
            --ngw-head-font-family,
            --ngw-text-font-family,
            "Roboto"
        ),
        sans-serif;
    --ngw-head-font-weight: 500;
}
