.nextgis.claro {
    .dijitCalendar {
        border: 0;
        background-color: #fff;

        &:hover,
        &.dijitCalendarHover,
        &.dijitCalendarActive {
            border: 0;
            background-color: #fff;
        }

        table {
            margin-top: 8px;
            margin-bottom: 4px;
        }

        .dijitDropDownButton {
            .dijitButtonNode {
                border-color: var(--input-border-color);
            }

            &:hover,
            &.dijitDropDownButtonHover {
                .dijitButtonNode {
                    border-color: rgba(0, 0, 0, 0.04);
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    .dijitCalendarPreviousMonth,
    .dijitCalendarNextMonth {
        border-bottom-color: var(--divider-color);
        background-color: var(--secondary);

        .dijitCalendarDateLabel {
            border-color: var(--secondary);
        }
    }

    .dijitCalendarDateTemplate {
        color: var(--text-base);
        font-weight: normal;
        font-size: 13px;
    }

    .dijitCalendarSelectedDate .dijitCalendarDateLabel {
        border-color: var(--primary);
        background-color: var(--primary);
        color: #fff;
        font-family: var(--ngw-text-font-family-list);
    }

    .dijitCalendarSelectedDate.dijitCalendarHoveredDate
        .dijitCalendarDateLabel {
        border-color: var(--primary);
        background-color: var(--primary);
        color: #fff;
    }

    .dijitCalendarHoveredDate .dijitCalendarDateLabel {
        border-color: rgba(0, 0, 0, 0.04);
        background-color: rgba(0, 0, 0, 0.04);
        color: var(--primary);
    }

    .dijitCalendarDayLabel {
        color: var(--text-base);
        font-size: 13px;
    }

    .dijitCalendarMonthLabel {
        color: var(--text-base);
        font-size: 14px;
    }

    .dijitCalendarYearLabel {
        padding: 2px 0 3px;
        color: var(--text-base);
        font-size: 14px;
    }

    .dijitCalendarNextYear,
    .dijitCalendarPreviousYear {
        padding: 2px 4px;
        color: var(--text-base);
        font-size: 13px;

        &:hover,
        &.dijitCalendarNextYearHover,
        &.dijitCalendarPreviousYearHover {
            border: 0;
            border-radius: var(--border-radius-root);
            background-color: rgba(0, 0, 0, 0.04);
            padding: 2px 4px;
        }
    }

    .dijitCalendarPopup {
        margin-top: 6px;
    }

    .dijitCalendarMonthMenu .dijitCalendarMonthLabelHover,
    .dijitCalendarMonthMenu .dijitCalendarMonthLabelActive {
        border-color: rgba(0, 0, 0, 0.04);
        background-image: none;
        background-color: rgba(0, 0, 0, 0.04);
    }
}
