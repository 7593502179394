.nextgis.claro {
    .dijitSplitterV {
        margin-left: -5px;
        background-image: none;
    }

    .dijitSplitterH {
        margin-top: -5px;
        background-image: none;
    }

    .dijitSplitterHHover,
    .dijitSplitterVHover,
    .dijitSplitterHActive,
    .dijitSplitterVActive {
        background-color: rgba(229, 238, 247, 0.48);

        .dijitSplitterThumb {
            background-color: transparent;
        }
    }
}

.dj_ios .nextgis.claro,
.dj_android .nextgis.claro {
    .dijitSplitterV {
        margin-left: -11px;
    }

    .dijitSplitterH {
        margin-top: -11px;
    }
}
