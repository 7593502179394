.dijitTree {
    -moz-user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.nextgis.claro {
    .dijitTreeRow,
    .dijitTreeNode .dojoDndItemBefore,
    .dijitTreeNode .dojoDndItemAfter {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .dijitTreeRowSelected,
    .dijitTreeRowHover,
    .dijitTreeRowActive {
        border: 0;
        background-image: none;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .dijitTreeRowHover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .dijitTreeRowSelected {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .dijitTreeNode * {
        cursor: pointer;
    }

    .dijitTreeNodeDisabled {
        opacity: 0.24;

        * {
            cursor: not-allowed;
        }

        .dijitTreeRowSelected {
            background: transparent;
        }
    }
}
