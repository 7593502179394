.nextgis.claro {
    .dijitTabPaneWrapper,
    .dijitTabContainerTop-tabs,
    .dijitTabContainerBottom-tabs,
    .dijitTabContainerLeft-tabs,
    .dijitTabContainerRight-tabs {
        border-color: var(--divider-color);

        .dijitTab {
            transition: none;
            margin-right: 0;
            box-shadow: none;
            border: 1px solid transparent;
            border-bottom: 0;
            background: none;
            padding: 0px 12px;
            height: 39px;
            color: var(--text-secondary);
            font-size: 13px;
            line-height: 38px;
            letter-spacing: 0.8px;
            text-transform: uppercase;

            &:hover {
                color: var(--text-base);
            }
        }

        .dijitTabChecked {
            color: var(--text-base);
        }

        .dijitTabError {
            color: fade(#f44336, 54%);

            &:hover,
            &.dijitTabChecked {
                color: fade(#f44336, 87%);
            }
        }
    }

    .dijitTabContainerTop-tabs {
        .dijitTab {
            top: 0;
            margin-bottom: -1px;
            border-top: 3px solid transparent;
        }

        .dijitTabChecked {
            border-top-color: var(--primary);
            border-right-color: var(--divider-color);
            border-left-color: var(--divider-color);
            background-color: #fff;
        }
    }

    .dijitTabContainerTopChildWrapper.dijitVisible {
        overflow: auto;
    }
}
